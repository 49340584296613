import React, { useRef, useMemo, useEffect, useState, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import './styles/navBar.css';

const BaseNavLink = styled.a.attrs({ className: 'base-nav-link' })``;

const NavLink = styled(BaseNavLink).attrs({ as: Link })``;

const ExternalNavLink = styled(BaseNavLink).attrs({ as: 'a' })``;

const ActiveDot = styled.div.attrs(({ left, isInitial }) => ({
  className: `active-dot${isInitial ? ' initial' : ''}`,
  style: { left: `${left}px` },
}))``;

const NavBar = ({ show, theme, themeToggled }) => {
  const location = useLocation();
  const links = useMemo(() => [
    { path: '/', label: 'Home' },
    { path: 'https://renskursa.github.io/Simple-3D-Model-Viewer/', label: '3D Model Viewer', external: true, openInNewTab: true },
    { path: '/about', label: 'About Me', external: false, openInNewTab: false, state: { fromNavigation: true } },
  ], []);
  const activeIndex = useMemo(() => links.findIndex(link => link.path === location.pathname), [links, location.pathname]);

  const [dotPosition, setDotPosition] = useState(0);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const linkRefs = useRef([]);

  const calculateDotPosition = useCallback(() => {
    if (linkRefs.current[activeIndex]) {
      const linkElement = linkRefs.current[activeIndex];
      const linkRect = linkElement.getBoundingClientRect();
      const containerRect = linkElement.parentElement.getBoundingClientRect();
      setDotPosition(linkRect.left - containerRect.left + linkRect.width / 2 - 3);
    }
  }, [activeIndex]);

  useEffect(() => {
    calculateDotPosition();
    // Remove initial render flag after first position calculation
    if (isInitialRender) {
      setTimeout(() => setIsInitialRender(false), 100);
    }
  }, [activeIndex, calculateDotPosition, isInitialRender]);

  return (
    <nav className={`nav-bar-container ${themeToggled ? 'theme-toggled' : ''} ${!show ? 'hidden' : ''}`} style={{ opacity: show ? 1 : 0 }}>
      <div className="nav-links">
        {links.map((link, index) => (
          link.external ? (
            <ExternalNavLink
              key={index}
              href={link.path}
              target={link.openInNewTab ? "_blank" : "_self"}
              rel={link.openInNewTab ? "noopener noreferrer" : ""}
              ref={el => (linkRefs.current[index] = el)}
            >
              {link.label}
            </ExternalNavLink>
          ) : (
            <NavLink
              key={index}
              to={link.path}
              state={link.state}
              target={link.openInNewTab ? "_blank" : undefined}
              rel={link.openInNewTab ? "noopener noreferrer" : undefined}
              className={location.pathname === link.path ? 'active' : ''}
              ref={el => (linkRefs.current[index] = el)}
            >
              {link.label}
            </NavLink>
          )
        ))}
        <ActiveDot left={dotPosition} isInitial={isInitialRender} />
      </div>
    </nav>
  );
};

export default NavBar;
